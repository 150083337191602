.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a
{
  text-decoration: none!important;
  color: #ffe600!important;
}

.nav-header 
{
  background-color: #911414;
  color: #ffffff;
  padding: 10px;
  padding-left: 15px;
  width: 100%;
}

.nav-header .active
{
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.nav-sub-header .active
{
  background-color: rgba(255, 255, 255, 0.25) !important;
}

.nav-header a
{
  text-decoration: none;
  color: #ffe600;
  padding: 12px;
  font-size: 13pt;
}

.nav-sub-header
{
  background-color: #000000;
  color: #ffffff;
  padding: 7px;
  padding-left: 15px;
  width: 100%;
}

.nav-sub-header a
{
  text-decoration: none!important;
  color: #ffffff!important;
  padding: 9px;
  font-size: 11pt;
}

.left-header
{
  display: inline;
  position: relative;
  left: 0px;
}

.right-header
{
  display: inline;
  position: absolute;
  right: 0px;
}

.content
{
  margin-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
  padding-bottom: 100px;
}

.center-image
{
  margin-left: auto;
  margin-right:auto;
  display: block;
}

.center-text
{
  text-align: center;
}

.btn-primary
{
  --bs-btn-color: #ffe600!important;
  --bs-btn-bg: #911414!important;
  --bs-btn-border-color: #911414!important;
  --bs-btn-hover-color: #ffe600!important;
  --bs-btn-hover-bg: #d33838!important;
  --bs-btn-hover-border-color: #d33838!important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #ffe600!important;
  --bs-btn-active-bg: #911414!important;
  --bs-btn-active-border-color: #911414;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffe600!important;
  --bs-btn-disabled-bg: #490b0b!important;
  --bs-btn-disabled-border-color: #490b0b!important;
}

.list-layout-button
{
  margin: 10px;
}

.display-show
{
  display: block !important;
}

.display-hide
{
  display: none !important;
}

.list-layout-modal
{
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.footer
{
  background-color: #911414;
  position: fixed;
  bottom: 0px;
  width: 100%;
  color: #fff;
}

.footer-copyright
{
  margin-top: 8px;
  text-align: center;
  font-size: 11pt;
}

.footer-api-version
{
  text-align: center;
  margin-top: 2px;
  margin-bottom: 8px;
  font-size: 9pt;
  color: #ffe600;
}

.sm-table{
  border: #440000 1px solid;
}

.sm-table thead{
  background-color: #911414;
  color: #ffe600;
}

.sm-table th {
  padding: 4px;
  font-size: 11pt;
  font-weight: 500;
  padding-left: 10px;
}

.sm-table {
  background-color: #d82c2c;
}

.sm-table tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,.08);
}

.sm-table td {
  padding: 10px;
  color: #fff;
  font-size: 15pt;
  font-weight: 400;
}

.content-menu-nav a
{
  color: #000!important;
}

.card
{
  background-color: #d82c2c!important;
  color: #fff!important;
}

.card .text-secondary
{
  color: #fff!important;
}

.card .list-group-item
{
  color: #fff!important;
  background-color: rgba(0,0,0,0)!important;
}

.card .list-group
{
  border-top: 1px solid #fff!important;
  border-bottom: 1px solid #fff!important;
}