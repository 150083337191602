html,
body {
  height: 100%;
}

.form-signin {
  max-width: 330px;
  padding: 1rem;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"], .form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.login-content
{
  padding: 10%;
}

.login-form-error
{
  position: absolute;
  top: 3px;
  right: 10px;
  color: #cf2323;
  vertical-align: middle;
  z-index: 99;
}

.form-error
{
  color: #cf2323;
  padding: 5px;
  font-size: 13pt;
}